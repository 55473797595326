import React from "react";

 interface IYoutubeEmbed{
    embedId:string;
 }

const YoutubeEmbed = ({ embedId }:IYoutubeEmbed) => (
  <div className="video-responsive">
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{width:"410px", height:'250px'}}
      title="Embedded youtube"
    />
  </div>
);


export default YoutubeEmbed;