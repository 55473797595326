import React, { useState } from 'react';
import FormValues from '../../components/FormValues';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select, { components } from 'react-select';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Controller, useForm } from 'react-hook-form';
import citiesUSA from '../../utils/usStates';
import { Label } from 'reactstrap';

import { toast, ToastContainer } from "react-toastify";
import { setLoader } from '../../redux/actions';
import { useRedux } from '../../hooks';
import { countries } from '../../utils/countries';

const customStyles = {
  control: () => ({
    minWidth: '100%',
    display: 'flex',
    height: '40px',
    borderRadius: '6px',
    background: '#F8F8F8',
    borderTop: 'transparent',
    borderLeft: 'transparent',
    borderRight: 'transparent',
    fontSize: '12px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',

    //   padding: "6px 3px 3px 6px",
    color: '#BDBDBD',
    alignItems: 'left',
    justifyContent: 'left',
  }),
  option: () => ({
    cursor: 'pointer',
    fontSize: '10px',
    marginBottom: '2px',
    padding: '10px',
    color: '#BDBDBD',
    '&:hover': {
      background: '#2698C6',
      color: 'black',
    },
  }),
};

export const ContactUs = () => {
  const { dispatch, useAppSelector } = useRedux();
  const {activeAboutPage, loader
  } = useAppSelector((state) => ({
    activeAboutPage:state.Pages.activeAboutPage,
    loader: state.Pages.loader
    }));
   
    const [comments, setComments]=useState<string>();
    const showSuccessNotification = (message: any) => {
      toast.success(message);
      reset();
    };
    
    const showErrorNotification = (error: any) => {
      toast.error(error);
      reset();
    };
  const resolver = yupResolver(
    yup.object().shape({
      firstName: yup
        .string()
        .required('Please enter your first name.'),
      lastName: yup
        .string()
        .required('Please enter your last name.'),
      email: yup.string().required('Please Enter E-mail.'),
      company: yup.string().required('Required Field.'),
      state: yup.object().required('Required Field.'),
    })
  );

  const defaultValues: any = {
    email: '',
  };

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
    reset,
  } = methods;

  const [phone, setPhone] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);

  const countryOptions = countries.map((country:any) => ({
    value: country.code2,
    label: country.name
  }));
  
  // Get states for the selected country
  const stateOptions = selectedCountry ? (() => {
    const country = countries.find((country: any) => country.code2 === selectedCountry);
    return country ? country.states.map((state:any) => ({
      value: state.code,
      label: state.name
    })) : [];
  })() : [];
  
  const onSubmit = (values:any) => {
    //console.log(values)
    dispatch(setLoader(true));
    console.log(values)
    const contacts = {
        "name":`${values.firstName} ${values.lastName}`,
        "email":values.email,
        "message":comments,
        "phone":phone,
        "company":values.company,
        "state":values.state && values.state.label &&values.state.label,
        "type":"contact_form_kc"

    };
    console.log(contacts);
    try {
      fetch('https://konzortiacapital.com/save.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contacts),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message == 'Data received successfully') {
          console.log("success");
          dispatch(setLoader(false));

          showSuccessNotification('Successfully submitted, We will contact you soon');
        } else {
          showErrorNotification("An error Occured, Try again later!");
          dispatch(setLoader(false));
        }
      }).catch((err) => {
        showErrorNotification("An error Occured, Try again later!");
        dispatch(setLoader(false));
      });

    } catch (error) {
        console.error('Error submitting form:',error);
        showErrorNotification("An error Occured, Try again later!");
        dispatch(setLoader(false));
    }
  }

  console.log(loader,'loader')
  return (
    
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className='pl-2'>
      <div className='bg-gray rounded center-div py-4 '>
      
        <>
<span className='font-primary font-size-16 font-bold'>
          Request Information
        </span>
        <div className='w-12/12'>
          <div className='flex flex-col'>
            <div className='flex flex-row w-12/12'>
              <div className='pt-2 w-100 px-2 pb-2'>
                {' '}
                <FormValues
                  type='text'
                  name='firstName'
                  control={control}
                  errors={errors}
                  register={register}
                  placeholder='First Name'
                  className='form-control w-100 font-primary font-size-12 '
                  hidePasswordButton={true}
                />
              </div>
              <div className='pt-2 w-100 px-2 pb-2'>
                {' '}
                <FormValues
                  type='text'
                  name='lastName'
                  control={control}
                  errors={errors}
                  register={register}
                  placeholder='Last Name'
                  className='form-control w-100 font-primary font-size-12 '
                  hidePasswordButton={true}
                />
              </div>
            </div>
            <div className=' px-2 pb-2'>
              {' '}
              <FormValues
                type='text'
                name='email'
                control={control}
                errors={errors}
                register={register}
                placeholder='Email'
                className='form-control font-primary font-size-12 '
                hidePasswordButton={true}
              />
            </div>
            <div className='flex flex-row w-100'>
              <div className='pt-2 w-100 px-2 pb-2'>
                {' '}
                <PhoneInput
                  type='tel'
                  required
                  placeholder='Phone number'
                  // maxLength="18"
                  value={phone !== null ? phone : ''}
                  defaultCountry='US'
                  onChange={(e: any) => {
                    setPhone(e);
                  }}
                  // onBlur={(e) => {setNewPhone(e); changePhoneNumber()}}
                />
              </div>
            
            </div>
            <div className='pt-2 flex flex-col w-100 px-2 pb-2'>
      <Controller
        name='country'
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            {...field}
            options={countryOptions}
            isClearable={true}
            styles={customStyles}
            placeholder={<div className='font-label pl-1'>Country</div>}
            className='font-label w-100 mb-2'
            onChange={(option) => {
              field.onChange(option);
              setSelectedCountry(option ? option.value : null);
            }}
          />
        )}
      />
      {selectedCountry && stateOptions.length > 0 && (
        <Controller
          name='state'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              options={stateOptions}
              isClearable={true}
              styles={customStyles}
              placeholder={<div className='font-label pl-1'>State</div>}
              className='font-label w-100'
            />
          )}
        />
      )}
    </div>
            <div className=' px-2 pb-2'>
              {' '}
              <FormValues
                type='text'
                name='company'
                control={control}
                errors={errors}
                register={register}
                placeholder='Company/Firm'
                className='form-control font-primary font-size-12 '
                hidePasswordButton={true}
              />
            </div>
            <div className=' px-2 pb-2 w-12/12 '>
              {' '}
              <textarea
              placeholder='Questions/Comments'
              className="form-control font-primary font-size-12"
rows={8}
onBlur={(e:any) => setComments(e.target.value)}              />
            </div>
            <div className='font-primary font-size-8 text-black px-4'>
            By clicking the send button, you agree to our privacy policy, coockie policy, and terms of use. You also agree to receive InvestHub’s emails and messages about our products and services.
            </div>
            <div className='w-1212 flex right-div px-4 pt-4'>
    <button className='btn-primary text-white font-primary flex  justify-center content-center' type="submit" disabled={loader}>
        { loader ? <div className="mt-2 spinner" ></div> : <span className="mt-1">Send</span>}
    </button>
</div>
          </div>
        </div>
        </>
     
      </div>
    </div>
    </form>
  );
};
