import React, { useEffect, useState } from "react";


import { useForm } from "react-hook-form";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { useRedux } from "../hooks";
import { parseCookies } from "nookies";
import { setActiveAboutPage, setActiveState } from "../redux/pages/actions";
export interface activePage{
    page:string;
}
export default function NavBar({page}:activePage) {
    const [navbar, setNavbar] = useState(false);
    const [logged, setLogged] = useState(false);
    const { dispatch, useAppSelector } = useRedux();
    const {activeState
    } = useAppSelector((state) => ({
        activeState:state.Pages.activeState
      }));
  
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
  
    //   const cookies: any = parseCookies();
    const { "@Konzortia.loggedUser": cookies }: any = parseCookies();
    const [cookieData, setCookieData] = useState<any>(null);
    const [userData, setUserData] = useState<any>();
    const [userPhoto, setUserPhoto] = useState<any>();
  
    // useEffect(() => {
    //   if (cookies) {
    //     setCookieData(JSON.parse(cookies));
    //     dispatch(setIsUserLogged(true));
    //   }
    // }, [cookies]);
  
    // const [nameUser, setNameUser] = useState(userLogged && userLogged.wt.rV[0])
    // useEffect(() => {
    //     if(userLogged !== null){
    //         setNameUser(userLogged.wt.rV[0])
    //     }
    // },[userLogged])
    // console.log(cookieData)
  
  
    // useEffect(() => {
    //   if (cookieData && cookieData.lead_id) {
    //     dispatch(getSettingsProfile(cookieData.lead_id));
    //   }},[cookieData])
  
    //   useEffect(() => {
    //     if (settingsProfile && settingsProfile.user) {
    //       setUserData(settingsProfile.user);
    //     }
    //   }, [settingsProfile]);
  
  
    //   useEffect(() => {
    //     if (userData && userData.photo) {
    //       setUserPhoto(userData.photo);
    //     }
    //   }, [userData]);
  
    return (
      <div  className="  top-0 z-10">
        <div className="w-full overflow-auto  px-16 mx-auto py-8 bg-header shadow-lg">
          <div className="justify-between  lg:max-w-7xl md:items-center md:flex md:px-8">
            <div>
              <div className="flex items-center justify-between py-3 md:py-5 md:block">
                <a
                  className="pointer"
                  onClick={() => dispatch(setActiveState("home"))}
                >
                  <img
                    src="/konzortiaLogoHeader.svg"
                    alt="user-profile"
                    className=" mr-1 logo-position"
                  />{" "}
                </a>
                <div className="md:hidden">
                  <button
                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(!navbar)}
                  >
                    {navbar ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-black"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-black"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`flex-1  text-align-left  pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                  navbar ? "block" : "hidden"
                }`}
              >
                <ul className="flex flex-col p-4 mt-4 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium ">
                  <li
                    className={
                      page === "home"
                        ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                        : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                    }
                  >
                    <a onClick={() => 
                     { 
                      window.location.href=`${process.env.REACT_APP_LOCAL_URL}home`}
                      }>
                      Home
                    </a>
                  </li>
                  <li
                    className={
                      page === "about"
                      ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                      : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                    }
                  >
                    <a onClick={() => 
                       { 
                        dispatch(setActiveAboutPage("about"));
                        window.location.href=`${process.env.REACT_APP_LOCAL_URL}about`}
                  }>About</a>
                  </li>
                  <li
                    className={
                      page === "investment"
                      ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                        : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                    }
                  >
                    <a onClick={() =>    { 
                      window.location.href=`${process.env.REACT_APP_LOCAL_URL}investment`}}>
                      Investment
                    </a>
                  </li>
               
                  <li
                    className={
                      page === "careers"
                      ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                      : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                    }
                  >
                    <a onClick={() => {
                        { 
                          dispatch(setActiveAboutPage("careers"));
                          window.location.href=`${process.env.REACT_APP_LOCAL_URL}careers`}}}>
                      Careers
                    </a>
                  </li>
              
                  <li
                    className={
                      page === "news"
                      ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                        : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                    }
                  >
                    <a onClick={() => 
                       { 
                        window.location.href=`https://newsroom.konzortia.capital`}
                      }>
                      News
                    </a>
                  </li>
                  <li
                    className={
                      page === "contact"
                      ? "font-primary color-primary pointer font-size-20 mr-3 hover:primary"
                        : "font-primary text-black pointer font-size-20 mr-3 hover:text-primary"
                    }
                  >
                    <a onClick={() => 
                        { 
                            window.location.href=`${process.env.REACT_APP_LOCAL_URL}contact`}
                      
                      }>
                      Contact
                    </a>
                  </li>
               
             
                </ul>
  
            
              </div>
            </div>
         
                
                 
                    </div>
                  </div>
                </div>
              )
            }
  
        
    
  
  