import React from "react";
import {BsInstagram} from 'react-icons/bs'
import {FaFacebookSquare} from 'react-icons/fa'
import {FaTwitterSquare} from 'react-icons/fa'
import {ImLinkedin} from 'react-icons/im'

interface ItemProps{
    links?:any;
    title:any;
}
const SocialMedia = ({ links, title }:ItemProps ) => {
  return (
    <>
    {/* <h1 className="mb-1 center-div font-primary color-primary font-bold">{title}</h1> */}
    <div className="flex flex-row center-div pt-24 ">
      <div className=" hover:text-black  mr-2 cursor-pointer font-primary color-primary" >
          <FaFacebookSquare size={25}/>
        </div>
        <div className=" hover:text-black mr-2 cursor-pointer font-primary color-primary" >
          <BsInstagram size={25}/>
        </div>
        <div className=" hover:text-black mr-2 cursor-pointer font-primary color-primary" >
          <FaTwitterSquare size={25}/>
        </div>
        <div className=" hover:text-black cursor-pointer font-primary color-primary" >
          <ImLinkedin size={25}/>
        </div>
    </div>
    </>
  );
};

export default SocialMedia;