import React, { useEffect, useMemo, useState } from 'react';
import ItemsContainer from './ItemsContainer';
import SocialIcons from './SocialIcons';
import {BsInstagram} from 'react-icons/bs'
import {FaFacebookSquare} from 'react-icons/fa'
import {FaTwitterSquare} from 'react-icons/fa'
import {ImLinkedin} from 'react-icons/im'
export const Footer = () => {



    return (<>
 <footer className=" footer pb-4 text-white mt-2">
      <div className="justify-between items-center sm:px-12 px-4   py-4">
        <div>
        </div>
      </div>
      <ItemsContainer />
    </footer>   
    </>
    )
}

export const FooterSocialMedia = () => {



    return (<>
      <div className='white-line-footer'></div>
 <footer className=" footer  text-white mt-2">
      
    <div className="flex flex-row center-div ">
      <div onClick={() => window.open("https://www.facebook.com/InvestHub.Kc/")} className=" hover:text-orange-400 mr-2 cursor-pointer font-noah-black-700" >
          <FaFacebookSquare size={50}/>
        </div>
        <div onClick={() => window.open("https://www.instagram.com/investhub_kc/")} className=" hover:text-orange-400 mr-2 cursor-pointer font-noah-black-700" >
          <BsInstagram size={50}/>
        </div>
        <div  onClick={() => window.open("https://twitter.com/InvestHub_Kc/")} className=" hover:text-orange-400 mr-2 cursor-pointer font-noah-black-700" >
          <FaTwitterSquare size={50}/>
        </div>
        <div onClick={() => window.open("https://www.linkedin.com/company/kcinvesthub/")} className=" hover:text-orange-400 cursor-pointer font-noah-black-700" >
          <ImLinkedin size={50}/>
        </div>
    </div>
    </footer>   
    </>
    )
}