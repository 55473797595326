import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Home } from "./pages/Home/Home";
import { About } from "./pages/About/About";
import { Investment } from "./pages/Investment/Investment";
import { Careers } from "./pages/Careers/Careers";
import { Contact } from "./pages/Contact/Contact";
import { Privacy } from "./pages/Privacy/Privacy";
import { Invest } from "./pages/Invest/Invest";

function App() {
  return (



    <div className="App bg-white h-screen w-screen overflow-x-hidden">
      <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/investment" element={<Investment />}></Route>
        <Route path="/careers" element={<Careers />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>
        <Route path="/invest" element={<Invest />}></Route>
      </Routes>
      <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
